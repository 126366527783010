'use strict'

angular.module('app').
factory('CategoriesService', ['$rootScope', '$http','$log', '$httpParamSerializer',
        function ($rootScope, $http, $log, $httpParamSerializer) {
            return {
                getCategory: function (category_slug) {
                    var promise = $http.get($rootScope.global_app.uri + '/categories/' + category_slug).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getCategoriesById: function (category_slug, id) {
                    var promise = $http.get($rootScope.global_app.uri + '/categories/' + category_slug + '/' + id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                }
            }
        }
    ]
);