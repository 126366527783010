'use strict';

angular.module('app', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ngStorage',
    'ngMessages',
    'ui.router',
    'ui.bootstrap',
    'ui.utils',
    'ui.load',
    'ui.jq',
    'oc.lazyLoad',
    //'cacheBuster',
    'angulartics',
    'angulartics.google.analytics',
    'angularMoment',
    //'loadingStatus',
    'disableSubmit',
    'angular-loading-bar',
    'xeditable',
    'angular.filter',
    'checklist-model',
    'angularFileUpload',
    'uiSwitch',
    'toaster',
    'ae-datetimepicker',
    'templates'

]);
