//https://stackoverflow.com/questions/29954799/angularjs-how-to-trigger-event-when-scroll-reaches-to-the-bottom-of-the-scroll
angular.module('app')
    .directive('scrolly', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var raw = element[0];

            element.bind('scroll', function () {
                if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                    scope.$apply(attrs.scrolly);
                }
            });
        }
    };
});