app.service('FormsDataService', ['$filter', '$localStorage', function($filter, $localStorage) {
    this.form_data = {
        last_created_item: null,
        search_data: [],
        display_data: [],
        form_for_action: null
    };

    this.setSearchData = function(key, arr, form_fields) {
        var search_fields = {};
        var filter_fields = {};
        var query_string = '';
        var display_arr = [];

        angular.forEach(arr, function (field, key){
            var field_type = key.substring(0,key.indexOf("_"));

            if (field_type == 'search'){
                search_fields[key] = field;
            }

            if (field_type == 'filter'){
                filter_fields[key] = field;
            }
        });

        // create search url string
        angular.forEach(search_fields, function(value, key){
            if (value !== '' && value != null) {
                query_string += '&' + key + '=' + form_fields[key].default_search_operator + ' ' + value;

                display_arr.push({
                    'title' : form_fields[key].title,
                    'value' : value
                });
            }
        })

        // create filter url string
        angular.forEach(filter_fields, function(value1, key1){
            var total_value = '';

            if (Array.isArray(value1)) {
                angular.forEach(value1, function (value2) {
                    if (value2 !== '') {

                        query_string += '&' + key1 + '[]=' + value2;

                        // map to correct value
                        var search_value = $filter('filter')(form_fields[key1].options, { option_value: value2 }, true)[0];
                        if (search_value){
                            value2 = search_value.option_text;
                        }

                        total_value += value2 + ', ';
                    }
                });

                // trim ', '
                total_value = total_value.substring(0, total_value.length - 2)

            } else if (angular.isObject(value1) && !Array.isArray(value1)){

                angular.forEach(value1, function (value2, key2) {
                    if (value2 !== '' && value2 !== undefined) {

                        query_string += '&' + key1 + '[' + key2 + ']=' + value2;
                        total_value += '<span class="font-bold">' + $filter('unslugify')(key2) + '</span>: ' + $filter('unslugify')(value2) + ', ';
                    }
                });

                // trim ', '
                total_value = total_value.substring(0, total_value.length - 2);

            } else {
                // ignore if null
	            if(value1 != null) {
		            // map to correct value
		            if (form_fields[key1].template == 'select' && !form_fields[key1].params.depends_on) {

			            var search_value = $filter('filter')(form_fields[key1].options, { option_value: value1 }, true)[0];

		            } else if (form_fields[key1].params.depends_on) {

                        // get the subset of options based on the value of the depends_on field
                        var dependsOnValue = filter_fields[form_fields[key1].params.depends_on];
                        var options = form_fields[key1].options[dependsOnValue];

                        var search_value = $filter('filter')(options, { option_value: value1 }, true)[0];
                    }

                    if (search_value){
                        total_value = search_value.option_text;
                    }
		            query_string += '&' + key1 + '=' + value1;
                }
            }

            if (total_value) {
                display_arr.push({
                    'title': form_fields[key1].title,
                    'value': total_value
                });
            }
        });

        this.form_data.search_data[key] = query_string;
        this.form_data.display_data[key] = display_arr;

        $localStorage['form_search_data_' + key] = query_string;
        $localStorage['form_display_data_' + key] = display_arr;
    };

    this.getSearchData = function(key) {
        return this.form_data.search_data[key] ? this.form_data.search_data[key] : '';
    };

    this.clearSearchData = function(key) {
        this.form_data.search_data[key] = '';
        this.form_data.display_data[key] = '';

        $localStorage['form_search_data_' + key] = '';
        $localStorage['form_display_data_' + key] = '';
    }

    this.getSearchDisplayData = function(key) {
        return this.form_data.display_data[key];
    };

    this.setLastCreatedItem = function(item) {
        this.form_data.last_created_item = item;
    };

    this.getLastCreatedItem = function() {

        var last_created_item = this.form_data.last_created_item;
        this.form_data.last_created_item = null;

        return last_created_item;
    };

    this.resetSearchData = function(key) {
        this.form_data.search_data[key] = [];
        this.form_data.display_data[key] = [];
        $localStorage['form_search_data_' + key] = '';
        $localStorage['form_display_data_' + key] = '';
    };
}]);