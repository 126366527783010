'use strict'

angular.module('app').factory('AgendaService', ['$rootScope', '$http', '$log', '$httpParamSerializer', 'moment',
    function ($rootScope, $http, $log, $httpParamSerializer) {
        return {
            isLiveEvent: function (event) {
                if (event){
                    var duration = moment.parseZone(event.datetime_start).diff(moment.parseZone(event.recording_datetime_start), 'minutes');

                    // allow for 120 minutes pre-recording time
                    if (duration <= 120){
                        return true
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
        }
    }
]);