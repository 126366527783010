'use strict'

angular.module('app').
factory('UtilsService', ['$rootScope', '$http','$log', 'moment',
        function ($rootScope, $http,$log) {
            return {
                formatErrorMsg: function(msg) {
                    var error_msg = '';

                    if (msg.indexOf('|') !== -1) {
                        error_msg = msg.split('|');

                        var show_error_msg = '';

                        angular.forEach(error_msg, function (value, key) {
                            show_error_msg += value + "<br>";
                        })

                    } else {
                        show_error_msg = msg;
                    }

                    return show_error_msg;
                },
                formatPath: function(breadcrumb) {
                    if (breadcrumb) {
                        var bcrumb = breadcrumb.split('|');
                        var display_bcrumb = '';

                        if (bcrumb.length) {
                            angular.forEach(bcrumb, function (value) {
                                display_bcrumb += '<strong>' + value + '</strong> / ';
                            });
                        }

                        return display_bcrumb;
                    }
                },
                formatVenuePath: function(breadcrumb) {

                    if (breadcrumb) {
                        var breadcrumbs = breadcrumb.split('|').map(function (item) {
                            return item.trim();
                        });
                        var venue_path = '';
                        angular.forEach(breadcrumbs, function (b) {
                            venue_path += b + ', ';
                        })

                        // remove trailing ','
                        venue_path = venue_path.substring(0, venue_path.length - 2)
                        
                        return venue_path;
                    }
                },
                formatMomentDate: function(datetime, format, tz){

                    if (datetime){
                        var date = moment.parseZone(datetime).format(format);

                        if (tz){
                            moment.tz.load({
                                zones : [],
                                links : [],
                                version : '2020a'
                            });

                            return date + ' ' + moment.tz(tz).zoneAbbr();

                        } else {
                            return date;
                        }
                    } else {
                        return '';
                    }
                },
                htmlToPlainText: function(text) {
                    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
                }
            };
        }
    ]
);
