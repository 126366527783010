'use strict';

/* Controllers */

angular.module('app')
    .controller('AppController', ['UtilsService','$rootScope','$scope', '$localStorage', '$window','AuthService','$location','$state',
        function (Utils, $rootScope, $scope, $localStorage, $window, Auth, $location, $state) {

            $rootScope.app_section = 'login';

            // add 'ie' classes to html
            var isIE = !!navigator.userAgent.match(/MSIE/i);
            isIE && angular.element($window.document.body).addClass('ie');
            isSmartDevice($window) && angular.element($window.document.body).addClass('smart');

            // config
            $scope.app = {
                name: 'CRF FACULTY',
                version: '1.0',
                // for chart colors
                color: {
                    primary: '#7266ba',
                    info: '#23b7e5',
                    success: '#27c24c',
                    warning: '#fad733',
                    danger: '#f05050',
                    light: '#e8eff0',
                    dark: '#3a3f51',
                    black: '#1c2b36'

                },
                settings: {
                    themeID: 1,
                    navbarHeaderColor: 'bg-darkblue-only',
                    navbarCollapseColor: 'bg-darkblue-only',
                    asideColor: 'bg-purple',
                    headerFixed: false,
                    asideFixed: false,
                    asideFolded: false,
                    asideDock: true,
                    container: true
                }
            }

            $rootScope.global_app.name = $scope.app.name;

            $scope.back_history = function() {
                window.history.back();
            };

            $rootScope.show_back = false;

            $rootScope.alerts = [];

            $scope.closeAlert = function(form_type, index) {
                $rootScope.alerts[form_type].splice(index, 1);
            };

            $rootScope.isMobile = function(){

                // get width of document window
                var w = angular.element($window);
                var width = w.width();

                //w.bind('resize', function () {
                //width = w.width();
                //});

                if (width < 640){
                    return true;
                } else {
                    return false;
                }
            }

            $scope.$watch('app.settings', function () {
                if ($scope.app.settings.asideDock && $scope.app.settings.asideFixed) {
                    // aside dock and fixed must set the header fixed.
                    $scope.app.settings.headerFixed = true;
                }
                // save to local storage
                $localStorage.settings = $scope.app.settings;
            }, true);

            function isSmartDevice($window) {
                // Adapted from http://www.detectmobilebrowsers.com
                var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
                // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
                return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
            }

            $rootScope.logout = function(){
                Auth.logout().then(function (data) {
                    $state.go('access.signin');
                });
            }

            $rootScope.formatMomentDate = function(datetime, format, tz){

                if (datetime){
                    return Utils.formatMomentDate(datetime, format, tz);
                } else {
                    return '';
                }
            }

            // open image thumb lightbox view
            $scope.openLightboxModal = function (field_id, images) {

                var img_arr  = [];
                var img_index = 0;

                angular.forEach(images, function(value, key) {
                    this.push({
                        'url': '/image/show/' + value.id + '/?_ts=' + new Date().getTime(),
                        'thumbUrl': '/image/show/' + value.id + '/thumb?_ts=' + new Date().getTime()
                    });

                    if (value.id == field_id){
                        img_index = key;
                    }

                }, img_arr);

                Lightbox.openModal(img_arr, img_index);
            };
        }
    ]);
