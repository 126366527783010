'use strict'

angular.module('app').factory('EventsService', ['$rootScope', '$http', '$log',
    function ($rootScope, $http, $log) {
        return {
            getEvents: function (conference_id, category_id) {
                var events_filter = category_id == 'all' ? 'events?depth=1&sort_by=session-type' : 'events?filter_session_type_id[]=' + category_id + '&depth=1';

                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id + '/' + events_filter).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getEvent: function (event_id) {
                var promise = $http.get($rootScope.global_app.uri + '/events/' + event_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            }
        }
    }
]);