'use strict'

angular.module('app').
    factory('UserService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {

            return {
                getUser: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/show').then(function (response) {

                        var user = response.data;

	                    // TODO: temp, this should probably
	                    // be handled elsewhere

	                    user.user.isAdmin = user.user.roles.indexOf('admin') > -1;
	                    user.user.isFaculty = user.user.roles.indexOf('faculty') > -1;
                        return user;

                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },

                getCountries: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/countries').then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },

                getStates: function (country_code){
                    var promise = $http.get($rootScope.global_app.uri + '/countries/' + country_code+'/states/').then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
            }
        }
    ]);