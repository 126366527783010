'use strict'

angular.module('app').
factory('TagsService', ['$rootScope', '$http','$log', '$httpParamSerializer',
        function ($rootScope, $http, $log, $httpParamSerializer) {
            return {
                getTags: function (tag_slug) {
                    var promise = $http.get($rootScope.global_app.uri + '/tags/' + tag_slug).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                }
            }
        }
    ]
);