'use strict'

angular.module('app').factory('COIReviewService', ['$rootScope', '$http', '$log', '$httpParamSerializerJQLike',
    function ($rootScope, $http, $log, $httpParamSerializerJQLike) {
        return {
            getCOIReviewConferences: function (user_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conflict_reviewers/' + user_id + '/conferences').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getCOIReviewConference: function (conference_id, user_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conflict_reviewers/' + user_id +'/conferences/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getCOIReviewFaculty: function (conference_id, user_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conflict_reviewers/' + user_id + '/conferences/' + conference_id + '/faculty').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getCOIReviewFacultyAssignments: function (conference_id, user_id, faculty_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conflict_reviewers/' + user_id + '/conferences/' + conference_id + '/faculty/' + faculty_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConflictResolutionMethods: function () {
                var promise = $http.get($rootScope.global_app.uri + '/categories/conflict-resolution-method').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateAssignmentForReview: function (conference_id, faculty_id, faculty_assignments) {
                var promise = $http.post($rootScope.global_app.uri + '/conferences/' + conference_id + '/faculty/' + faculty_id + '/assignments/flag_for_review', faculty_assignments).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },

            finishReview: function (payload) {
                var promise = $http.post($rootScope.global_app.uri + '/conflict_reviewers/'
                    + payload.user_id +'/conferences/'
                    + payload.conference_id + '/faculty/'
                    + payload.faculty_id + '/complete_review', payload)
                    .then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                })
                return promise;
            },
        }
    }
]);