'use strict'

angular.module('app').factory('ConferencesService', ['$rootScope', '$http', '$log', '$httpParamSerializerJQLike',
    function ($rootScope, $http, $log, $httpParamSerializerJQLike) {
        return {
            getConference: function (conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getRecordingTimeSlots: function (conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id + '/recording_time_slots').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getCategories: function (conference_id, category_type) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id + '/categories/' + category_type).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            }
        }
    }
]);