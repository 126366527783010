// original code: http://amystechnotes.com/2015/05/06/tinymce-add-character-count/comment-page-1/
tinymce.PluginManager.add('charactercount', function (editor) {
    var self = this;

    function update() {
        var char_limit = editor.settings.charLimit;
        var char_str;
        if (char_limit){
            char_str = 'Characters: {0}/{1}';
        } else {
            char_str = 'Characters: {0}';
        }
        editor.theme.panel.find('#charactercount').text([char_str, self.getCount(), char_limit]);
    }

    editor.on('init', function () {
        var statusbar = editor.theme.panel && editor.theme.panel.find('#statusbar')[0];

        if (statusbar) {
            window.setTimeout(function () {
                statusbar.insert({
                    type: 'label',
                    name: 'charactercount',
                    text: ['Characters: {0}', self.getCount()],
                    classes: 'charactercount',
                    disabled: editor.settings.readonly
                }, 0);

                editor.on('setcontent beforeaddundo', update);

                editor.on('keyup', function (e) {
                    update();
                });
            }, 0);
        }
    });

    self.getCount = function () {
        var tx = editor.getContent({ format: 'raw' });
        var decoded = decodeHtml(tx);
        var decodedStripped = decoded.replace(/(<([^>]+)>)/ig, "").trim();
        var tc = decodedStripped.length;
        return tc;
    };

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
});