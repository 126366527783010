'use strict'

angular.module('app').
factory('TasksService', ['$rootScope', '$http','$log', '$localStorage',
    function ($rootScope, $http, $log, $localStorage) {
        return {
            getTask: function (conference_id, task_id){
                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id + '/tasks/' + task_id).then(function (response) {

                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            }
        }
    }
]);
