'use strict';

angular.module('app').
    service('AuthService', ['$rootScope', '$http', '$log', '$location', '$localStorage',
        function ($rootScope, $http, $log, $location, $localStorage) {

            $rootScope.global_app = {
                uri: $location.protocol() + "://" + $location.host() + ":" + $location.port()
            }

            this.check = function (user) {
                var promise = $http.get($rootScope.global_app.uri + '/user/show').then(function (response) {

                    if (response.success){
                        $rootScope.isAuthenticated = response.data.success;
                    }

                    return response.data;

                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.login = function (user) {

                if (user.token) {
                    var credentials = {
                        'remember_token': user.token
                    }
                } else {
                    var credentials = {
                        'username': user.username,
                        'password': user.password
                    }
                }
                var promise = $http.post($rootScope.global_app.uri + '/auth/login', credentials).then(function (response) {

                    if (response.success){
                        $rootScope.isAuthenticated = response.data.success;
                    }

                    return response.data;

                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.logout = function () {
                var promise = $http.get($rootScope.global_app.uri + '/auth/logout').then(function (response) {

                    $rootScope.isAuthenticated = response.data.logout;

                    return response.data;

                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };
        }
    ]
);



