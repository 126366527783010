angular.module('disableSubmit',[])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('disableSubmitInterceptor');
    }])
    .factory('disableSubmitInterceptor', ['$q', '$rootScope', function ($q, $rootScope) {
        var activeRequests = 0;
        var started = function () {
            if (activeRequests == 0) {
                $rootScope.disable_submit = true;
            }
            activeRequests++;
        };
        var ended = function () {
            activeRequests--;
            if (activeRequests == 0) {
                $rootScope.disable_submit = false;
            }
        };
        return {
            request: function (config) {
                started();
                return config || $q.when(config);
            },
            response: function (response) {
                ended();
                return response || $q.when(response);
            },
            responseError: function (rejection) {
                ended();
                return $q.reject(rejection);
            }
        }
    }]);