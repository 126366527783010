'use strict'

angular.module('app').factory('FormsService', ['$rootScope', '$http', '$log', '$httpParamSerializer',
    function ($rootScope, $http, $log, $httpParamSerializer) {
        return {
            getForm: function (form_config) {

                var url = '';

                if (form_config.id != null) {
                    url += $rootScope.global_app.uri + '/forms/' + form_config.type + '/' + form_config.id + '?';
                    if (form_config.fieldsets){
                        url  += 'fieldsets=true&'
                    }
                    if (form_config.conference_id != null) {
                        url += 'conference_id=' + form_config.conference_id + '&'
                    }
                } else {
                    var form_url = '';
                    var form_group = '';

                    if (form_config.group){
                        form_group = form_config.group + '/';
                    }
                    var base_url = $rootScope.global_app.uri + '/forms/' + form_group + form_config.type;

                    if (form_config.parent_id != null) {
                        form_url += 'parent_id=' + form_config.parent_id + '&'
                    }
                    if (form_config.conference_id != null) {
                        form_url += 'conference_id=' + form_config.conference_id + '&'
                    }
                    if (form_config.faculty_id != null) {
                        form_url += 'faculty_id=' + form_config.faculty_id + '&'
                    }
                    if (form_config.campaign_id != null) {
                        form_url += 'campaign_id=' + form_config.campaign_id + '&'
                    }
                    if (form_config.campaign_type_id != null) {
                        form_url += 'campaign_type_id=' + form_config.campaign_type_id + '&'
                    }
                    if (form_config.okta_id != null) {
                        form_url += 'okta_id=' + form_config.okta_id + '&'
                    }
                    if (form_config.fieldsets){
                        form_url  += 'fieldsets=true'
                    }

                    if (form_url){
                        url = base_url + '?' + form_url;
                    } else {
                        url = base_url;
                    }
                }

                // beneb - trim trailing "&" from URL
                var amp = /&$/;
                url.replace(amp, "");

                var promise = $http.get(url, {
                    ignoreLoadingBar: true
                }).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateForm: function (form_config, form_data) {

                if (form_config.put_url){
                    var url = $rootScope.global_app.uri + form_config.put_url;

                    var promise = $http.put(url, form_data).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                } else if (form_config.post_url){
                    var url = $rootScope.global_app.uri + form_config.post_url;

                    var promise = $http.post(url, form_data).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                } else if (form_config.id != null) {
                    var url = $rootScope.global_app.uri + '/' + form_config.type + '/' + form_config.id

                    var promise = $http.put(url, form_data).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;

                } else {
                    var url = $rootScope.global_app.uri + '/' + form_config.type

                    var promise = $http.post(url, form_data).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                }
            }
        }
    }
]);

